<template>
  <div class="box">
    <div class="zhu_zhaung_tu_box">
      <v-chart :options="options" autoresize />
    </div>
    <span class="unit">单位: {{ unit }}</span>
  </div>
</template>

<script>
export default {
  name: "page1",
  components: {},
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    ispreview: Boolean,
  },
  data() {
    return {
      title: "",
      unit: '',
      options: {
        tooltip: {
          trigger: "item",
          show: false,
        },
        // 提示
        tooltip: {
          trigger: "item",
          show: true,
          textStyle: {
            color: '#fff',
            fontSize: 16
          }
        },
        legend: {
          top: "5%",
          left: "center",
          selectedMode: false,
          textStyle: {
            color: '#fff'
          },
          show: false
        },
        series: [
          {
            type: "pie",
            radius: ["40%", "70%"],
            center: ["50%", "70%"],
            // adjust the start angle
            startAngle: 180,
            label: {
              fontSize: 18,
              formatter(param) {
                // correct the percentage
                // return param.name + " (" + param.percent * 2 + "%)";
                return param.name + " " + param.value;
              },
            },
            data: [
              { value: 1048, name: "分类1" },
              { value: 735, name: "分类2" },
              { value: 580, name: "分类3" },
              { value: 484, name: "分类4" },
              { value: 300, name: "分类5" },
              {
                value: 3147,
                itemStyle: {
                  color: "none",
                  decal: {
                    symbol: "none",
                  },
                },
                label: {
                  show: false,
                },
              },
            ],
            itemStyle: {
              decal: {
                symbol: "none",
              },
              normal: {
                color: function(params) {
                  var colorList = [
                    "#65d186",
                    "#2BCB95",
                    "#F6BD16",
                    "#c05bdd",
                    "#f29e3c",
                    "#7a65f2",
                    "#65d186",
                    "#FF3B30"
                  ]; //每根柱子的颜色
                  return colorList[params.dataIndex];
                },
                shadowColor: "rgba(0,160,221,1)",
                //模糊细数
                shadowBlur: 4
              }
            },
          },
        ],
      },
    };
  },
  watch: {
    value(val) {
      this.options.series[0].data = val.data;
      let sum = 0
      for (let i in val.data) {
        sum += val.data[i].value
      }

      this.options.series[0].data.push(
        {
          value: sum,
          itemStyle: {
            color: "none",
            decal: {
              symbol: "none",
            }
          },
          label: {
            show: false,
          }
        },
      )
              
      this.unit = val.unit
    },
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="scss">
.echarts {
  width: 100%;
  height: 90%;
  overflow: hidden;
}

.box {
  width: 100%;
  height: 90%;
  position: relative;
}

.zhu_zhaung_tu_box {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0 10px;
  box-sizing: border-box;
}

.unit { position: absolute; left: 10px; bottom: 50px; color: #fff; font-size: 14px; }
</style>
