<template>
  <div class="box_1">
    <div class="header">
      <div class="text" @click="goToMenuPage()">大板镇{{ hamletName }}农业统计</div> 
    </div>

    <div class="content_box">
      <!-- 1 -->
      <div class="content_item_box">
        <!-- 种植种类 -->
        <div class="left_box">
          <div class="title"><span>种植种类</span></div>
          <bing-tu :value="data1" />
        </div>

        <!-- 种植面积 -->
        <div class="left_box">
          <div class="title"><span>产量统计</span></div>
          <zhu-zhaung-tu class="chart" :value="data3" />
        </div>
      </div> 

      <!-- 2 -->
      <div class="content_item_box">
        <div class="map_box">
          <hao-rao-qin v-if="hamletName == '浩饶沁嘎查'" />
          <tai-bu-dai v-if="hamletName == '太布呆嘎查'" />
          <da-leng v-if="hamletName == '大冷村'" />
          <gu-li-gu-tai v-if="hamletName == '古力古台嘎查'"/>
          <hong-qi v-if="hamletName == '红旗嘎查'" />
          <ji-bu-tu v-if="hamletName == '吉布吐嘎查'" />
          <xi-ha v-if="hamletName == '西哈嘎查'" />
          <wen-gen-tu v-if="hamletName == '温根吐嘎查'" />
          <shi-jia-zi v-if="hamletName == '十家子嘎查'"/>
          <su-ai-li v-if="hamletName == '苏艾力嘎查'" />
          <zhao-hu-du-ge v-if="hamletName == '昭胡都格嘎查'" />
        </div>

        <div class="context_box">
          全嘎查总土地面积 104 平方公里，
          其中：草木场面积 2.5万亩，耕地面积4.5万亩，林地面积3,4万亩，
          棚圈： 1400平米，饲草料加工设备：189台，青储窑：2100平米。
        </div>

        <div class="bottom_box">
          <div class="title"><span>种植人口</span></div>
          <ban-bing-tu :value="data2" />
        </div>
      </div> 

      <!-- 3 -->
      <div class="content_item_box">
        <!-- 病虫害统计 -->
        <div class="right_box">
          <div class="title"><span>病虫害统计</span></div>
          <zhu-zhaung-tu class="chart" :value="data4" />
        </div>

        <!-- 智能化统计 -->
        <div class="right_box">
          <div class="title"><span>机械化统计</span></div>
          <kong-xin-bing-tu :value="data5" />
        </div>
      </div> 
    </div>
  </div>
</template>

<script>
  import ZhuZhaungTu from '../charts/zhu-zhuang-tu.vue'
  import HaoRaoQin from '../maps/da-ban-hamlet/hao-rao-qin.vue';
  import BingTu from '../charts/bing-tu.vue'
  import TaiBuDai from '../maps/da-ban-hamlet/tai-bu-dai.vue';
  import DaLeng from '../maps/da-ban-hamlet/da-leng.vue';
  import GuLiGuTai from '../maps/da-ban-hamlet/gu-li-gu-tai.vue';
  import HongQi from '../maps/da-ban-hamlet/hong-qi.vue';
  import JiBuTu from '../maps/da-ban-hamlet/ji-bu-tu.vue';
  import WenGenTu from '../maps/da-ban-hamlet/wen-gen-tu.vue';
  import XiHa from '../maps/da-ban-hamlet/xi-ha.vue';
  import ShiJiaZi from '../maps/da-ban-hamlet/shi-jia-zi.vue';
  import SuAiLi from '../maps/da-ban-hamlet/su-ai-li.vue';
  import ZhaoHuDuGe from '../maps/da-ban-hamlet/zhao-hu-du-ge.vue';
  import BanBingTu from '../charts/ban-bing-tu.vue';
  import kongXinBingTu from '../charts/kong-xin-bing-tu.vue';

  export default {
    name: 'showPage1',
    components: { 
      ZhuZhaungTu, 
      BingTu,
      HaoRaoQin, 
      TaiBuDai, 
      DaLeng,
      GuLiGuTai,
      HongQi,
      XiHa,
      WenGenTu,
      ShiJiaZi,
      SuAiLi ,
      JiBuTu,
      ZhaoHuDuGe,
      BanBingTu,
      kongXinBingTu
    },
    data() {
      return {
        activeIndex: 0,
        data1: {},
        data2: {},
        data3: {},
        data4: {},
        data5: {},

        hamletName: ''

      }
    },
    mounted() {
      this.hamletName = this.$route.params.val
      console.log(this.hamletName);

      setTimeout(() => {
        this.initChart()
      }, 100)
    },
    methods: {
      // 初始化图表
      initChart() {
        this.data1 = {
          title: '种植种类',
          unit: '亩',
          data: [
            {"name": "玉米", "value": 320, "unit": ''},
            {"name": "甜玉米", "value": 460, "unit": ''},
            {"name": "小麦", "value": 650, "unit": ''},
            {"name": "葵花", "value": 1400, "unit": ''},
            {"name": "马铃薯", "value": 340, "unit": ''},
            {"name": "胡萝卜", "value": 220, "unit": ''},
            {"name": "香瓜", "value": 151, "unit": ''},
            {"name": "芸豆", "value": 23, "unit": ''}
          ]
        }

        this.data2 = {
          title: '种植人口',
          unit: '人',
          data: [
            {"name": "玉米", "value": 1452, "unit": '' },
            {"name": "甜玉米", "value": 5431, "unit": '' },
            {"name": "小麦", "value": 4355, "unit": '' },
            {"name": "葵花", "value": 854, "unit": '' },
            {"name": "马铃薯", "value": 5343, "unit": '' },
            {"name": "胡萝卜", "value": 876, "unit": '' },
            {"name": "香瓜", "value": 435, "unit": '' },
            {"name": "芸豆", "value": 866, "unit": '' }
          ]
        }

        this.data3 = {
          title: {
            text: "产量统计",
            left: "center",
            top: '0%',
            textStyle: {
              color: "#fff"
            }
          },
          yName: '吨',
          x: ['玉米', '甜玉米', '芸豆', '胡萝卜', '小麦', '葵花', '马铃薯', '香瓜'],
          y: [98, 69, 112, 43, 324, 142, 67, 87, 89]
        }

        this.data4 = {
          title: {
            text: "病虫害统计",
            right: "20px",
            top: '0%',
            textStyle: {
              color: "#fff"
            }
          },
          yName: '万',
          x: ['小麦锈病', '稻瘟病', '稻纹枯病', '蝗虫', '麦芽'],
          y: [18, 39, 12, 43, 24]
        }

        this.data5 = {
          title: '机械化统计',
          unit: '辆',
          data: [
            {"name": "大型拖拉机", "value": 320, "unit": ''},
            {"name": "收割机", "value": 460, "unit": ''},
            {"name": "播种机", "value": 650, "unit": ''},
            {"name": "打草机", "value": 1400, "unit": ''},
            {"name": "消毒剂", "value": 340, "unit": ''},
          ]
        }
      },

      clickTab(val) {
        this.activeIndex = val
        if (val == 0) {
          this.list = this.list1
        } else if (val == 1) {
          this.list = this.list2
        } else if (val == 2) {
          this.list = this.list3
        } else if (val == 3) {
          this.list = this.list4
        } else if (val == 4) {
          this.list = this.list5
        } else if (val == 5) {
          this.list = this.list6
        }
      },

      // 跳转到 menu 页面
      goToMenuPage() {
        this.$router.push({
          path: "/bigscreen/menuPage"
        })
      },

      // 跳转到 人口统计 图表详情
      goToPersonStateDetail() {
        let title = `xxx村人口统计`

        this.$router.push({
          path: "/bigscreen/personState" + title
        })
      },

      // 跳转到 机械化统计 图表详情
      goToMashineStateDetail() {
        let title = `xxx村机械化统计`

        this.$router.push({
          path: "/bigscreen/mashineState" + title
        })
      }
    }
  }
</script>

<style scoped lang="less">
  .activeColor { color: #2366fc !important; }

  @media screen and (min-width: 1200px) and (max-width: 1399px) {
    
  }
</style>